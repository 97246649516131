import React, { useEffect, useState } from 'react';
import { t } from '@superset-ui/core';
import { VianaFrameComponentProps } from 'src/explore/components/controls/DateFilterControl/types';
import { RangePicker } from 'src/components/DatePicker';
import moment from 'moment';
import { TimePicker, Col, Row } from 'antd';

export function VianaPOFrame(props: VianaFrameComponentProps) {
  // function getAdvancedRange(value: string): string {
  //   if (value.includes(SEPARATOR)) {
  //     return value;
  //   }
  //   if (value.startsWith('Last')) {
  //     return [value, ''].join(SEPARATOR);
  //   }
  //   if (value.startsWith('Next')) {
  //     return ['', value].join(SEPARATOR);
  //   }
  //   return SEPARATOR;
  // }
  // const advancedRange = getAdvancedRange(props.value || '');

  // const [since, until] = advancedRange.split(SEPARATOR);
  // if (advancedRange !== props.value) {
  //   props.onChange(getAdvancedRange(props.value || ''));
  // }

  const [startDate, setStartDate] = useState<moment.Moment>(
    moment().startOf('day'),
  );
  const [startTime, setStartTime] = useState<moment.Moment>(
    moment('00:00:00', 'HH:mm:ss'),
  );

  const dashboardTitle = document.querySelector('.dynamic-title')?.textContent;

  const [endDate, setEndDate] = useState<moment.Moment>(moment().endOf('day'));
  const [endTime, setEndTime] = useState<moment.Moment>(
    dashboardTitle === 'SAMLI'
      ? moment('23:59:00', 'HH:mm:ss')
      : moment('23:55:00', 'HH:mm:ss'),
  );
  // function onChange(control: 'since' | 'until', value: string) {
  //   if (control === 'since') {
  //     props.onChange(`${value}${SEPARATOR}${until}`);
  //   } else {
  //     props.onChange(`${since}${SEPARATOR}${value}`);
  //   }
  // }

  useEffect(() => {}, [props]);

  return (
    <>
      <div className="section-title">{t('Custom ')}</div>
      <RangePicker
        onChange={(_dateTime: any, formatString: any[]) => {
          if (formatString[0] !== '' || formatString[1] !== '') {
            props.onChange(
              `${formatString[0]} ${startTime.format('HH:mm:ss')} : ${
                formatString[1]
              } ${endTime.format('HH:mm:ss')}`,
            );
            setStartDate(moment(formatString[0]));
            setEndDate(moment(formatString[1]));

            props.onSetLabel(
              `${formatString[0]} ${startTime.format('HH:mm:ss')} - ${
                formatString[1]
              } ${endTime.format('HH:mm:ss')}`,
            );
          }
        }}
        // showTime
        // allowClear
        // value={[startDate, endDate]}
      />
      <Row gutter={8}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <TimePicker
            minuteStep={5}
            secondStep={60}
            onChange={(e: any) => {
              if (e !== null) {
                setStartTime(e);
                props.onChange(
                  `${startDate.format('YYYY-MM-DD')} ${e.format(
                    'HH:mm:ss',
                  )} : ${endDate.format('YYYY-MM-DD')} ${endTime.format(
                    'HH:mm:ss',
                  )}`,
                );

                props.onSetLabel(
                  `${startDate.format('YYYY-MM-DD')} ${e.format(
                    'HH:mm:ss',
                  )} - ${endDate.format('YYYY-MM-DD')} ${endTime.format(
                    'HH:mm:ss',
                  )}`,
                );
              }
            }}
            defaultValue={startTime}
          />
        </Col>

        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <TimePicker
            minuteStep={5}
            secondStep={60}
            onChange={(e: any) => {
              if (e !== null) {
                setEndTime(e);
                props.onChange(
                  `${startDate.format('YYYY-MM-DD')} ${startTime.format(
                    'HH:mm:ss',
                  )} : ${endDate.format('YYYY-MM-DD')} ${e.format('HH:mm:ss')}`,
                );

                props.onSetLabel(
                  `${startDate.format('YYYY-MM-DD')} ${startTime.format(
                    'HH:mm:ss',
                  )} - ${endDate.format('YYYY-MM-DD')} ${e.format('HH:mm:ss')}`,
                );
              }
            }}
            defaultValue={endTime}
          />
        </Col>
      </Row>
    </>
  );
}
